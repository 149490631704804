.draggable-table-container {
    display: flex;
    justify-content: center;
  }
  
  table {
    border-collapse: collapse;
    width: 94%; 
    margin: auto;
    margin-bottom: 30px; 
  }

  .table_Header {
    border: 1px solid #DCDCDC;
  }
  
  th,
  td {
    padding: 16px;
    text-align: left;
  }
  
  .card-row {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    transition: box-shadow 0.2s ease-in-out;
    border: 1px solid lightgray;
  }
  
  .card-row:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .card-row td {
    padding: 15px;
  }
  .card-row tr {
    margin-bottom: 1px;
  }

  
  .card-row:hover {
    cursor: grab;
  }
  
  .card-row:active {
    cursor: grabbing;
  }